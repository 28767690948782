/*------- custom fonts -------*/
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Thin.woff");
  font-weight: 100;
}

@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Light.woff");
  font-weight: 300;
}

@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GT America Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Titillium;
  src: url("../fonts/Titillium-Web/TitilliumWeb-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: SchnyderWideM;
  src: url("../fonts/Schnyder/SchnyderWideM-Light.woff");
  font-weight: 300;
}

@font-face {
  font-family: SchnyderSDemi;
  src: url("../fonts/Schnyder/SchnyderS-Demi.woff");
  font-weight: 400;
}
