/*------- custom styles -------*/
body {
  font-family: GTAmerica, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--txt11);
  color: #40423f;
  background-color: #555354;
}

// main viewport
.viewport {
  position: relative;
  overflow-x: hidden;
}

// responsive
@media only screen and (max-width: 576px) {
}

// section
.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

// container
.container {
  display: block;
  width: 100%;
  padding: 0 1.5rem;
  // max-width: 480px;
}

// @media only screen and (min-width: 768px) {
//   .container {
//     max-width: 960px;
//   }
// }

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
}

// buttons
.btn_primary {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  padding: 0 1.5625rem;
  height: 3.4375rem !important;
  line-height: 3.4375rem !important;
  min-width: 8.4375rem;
}

.btn_primary .label {
  color: #fff;
  line-height: 1;
  transition: color 0.2s;
}

.btn_primary::before {
  display: none;
}

.btn_primary:hover .label {
  color: #40423f;
}

.btn_primary .canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  // transform-origin: 0 100%;
  // transition: transform 350ms ease;
  z-index: -1;
  will-change: transform;
  background-color: #fff;
}

// .btn_primary:hover .canvas {
//   transform: scaleX(1);
//   transform-origin: 100% 0%;
// }

// button 2
.btn_2 {
  appearance: none;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
  padding: 1.125rem 2.3125rem 1.25rem;
  border-radius: 2px;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.15, 0.95);
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.btn_2:hover {
  color: #40423f;
  background-color: #ffffff;
}

// button hamburger
.btn_hamburger {
  margin-left: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -0.90625rem 0 0;
  width: 2.8125rem;
  height: 2.8125rem;
}

.btn_hamburger_wrap {
  width: 1rem;
  height: 0.5625rem;
  position: relative;
}

.btn_hamburger span {
  transform-origin: 8px 0.5px;
  transform: translate(0px, 0px);
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}

.btn_hamburger span:nth-child(1) {
  top: 0;
}

.btn_hamburger span:nth-child(2) {
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.btn_hamburger span:nth-child(3) {
  bottom: 0;
}

// link 1
.link_1 {
}

.link_1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
}

.link_1:hover::after {
  transform: scaleX(1);
}
