// blog list banner
.blog_list_banner {
  width: 100%;
  background-color: #555354;
}
.blog_list_banner_wrapper {
  width: 100%;
  padding-top: 9.11em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
h2.blog_list_banner_title {
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.35px;
  color: #e1e0dc;
  margin-bottom: 0;
  width: 100%;
  padding: 0;
}
.blog_list_filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-top: 1.875rem;
}
.blog_list_filter select {
  width: 100%;
  appearance: none;
  border: none;
  border-radius: 0;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: #fff;
  padding: 1.875em 0 2em;
  margin-bottom: 1.25em;
  background-color: transparent;
  background-image: url(../img/icon/select-bg.svg);
  background-size: 1.5em;
  background-repeat: no-repeat;
  background-position: calc(100% + 6px) 50%;
}
.blog_list_filter select option {
  color: #1b1b1b;
}

@media only screen and (min-width: 768px) {
  .blog_list_banner_wrapper {
    padding-top: 13.28rem;
    padding-bottom: 6.78rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  h2.blog_list_banner_title {
    font-size: 30px;
    line-height: 5.5rem;
    letter-spacing: -0.4px;
    width: 50%;
    max-width: 650px;
  }
  .blog_list_filter {
    width: 50%;
    max-width: 650px;
    justify-content: flex-end;
    padding-left: 1.5rem;
    padding-top: 0;
  }
  .blog_list_filter select {
    max-width: 160px;
    padding: 2em 1.5rem;
    margin-bottom: 0;
    background-position: calc(100% + 6px - 1.5rem) 50%;
    margin-right: -1.5rem;
  }
  .blog_list_filter select option {
  }
}

@media only screen and (min-width: 1024px) {
  .blog_list_banner_wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  h2.blog_list_banner_title {
    padding: 0 1.5rem;
  }
  .blog_list_filter select {
    margin-right: 0;
  }
}

// blog list content
.blog_list_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #787878;
  padding-bottom: 4em;
}
.blog_list {
  width: calc(100% + 1.75rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.875rem;
  padding: 1.5rem 0;
}
.blog_list_item {
  width: 100%;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: none;
  max-width: 460px;
  margin: 0 auto;
}

.blog_list_image {
  display: block;
  width: 100%;
  position: relative;
  /* padding-top: 56.26%; */
  margin-bottom: 2rem;
  height: 250px;
}
.blog_list_image a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.blog_list_image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.blog_list_desc {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.blog_list_id {
  display: flex;
  padding: 0;
  margin-bottom: 1rem;
}
.blog_list_id span {
  display: inline-block;
  font-family: GTAmerica;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.23px;
  color: #e4dfd4;
  -webkit-font-smoothing: antialiased;
}
.blog_list_id span::after {
  content: "\A0\2022\A0";
}
.blog_list_id span:last-child::after {
  display: none;
}
.blog_list_id a {
}
h2.blog_list_title {
  display: block;
}
h2.blog_list_title a {
  display: flex;
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.76px;
  color: #ededed;
  margin-bottom: 2.25em;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (min-width: 768px) {
  .blog_list {
    padding-top: 2rem;
  }
  .blog_list_item {
    width: 50%;
    margin: 0;
    padding-bottom: 2em;
    max-width: unset;
  }
  .blog_list_item:nth-child(2n - 1) {
    border-right: 1px solid #979797;
  }
  .blog_list_id {
    margin-bottom: 1em;
  }
}

@media only screen and (min-width: 992px) {
  .blog_list {
    padding-top: 4rem;
  }
  .blog_list_item {
    width: 33.33%;
    padding-bottom: 3em;
  }
  .blog_list_item:nth-child(3n - 1),
  .blog_list_item:nth-child(3n - 2) {
    border-right: 1px solid #979797;
  }
  .blog_list_item:nth-child(3n) {
    border-right: none;
  }
  .blog_list_image {
    margin-bottom: 2em;
  }
  .blog_list_desc {
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media only screen and (min-width: 1024px) {
  .blog_list_content {
    padding-bottom: 5em;
  }
  .blog_list {
    padding-top: 4em;
  }
  .blog_list_item {
    padding-bottom: 6em;
  }
}

// blog list more button
.blog_list_more {
  display: flex;
  justify-content: center;
}
