.home_instagram {
  width: 100%;
  overflow: hidden;
  background-color: #1b1b1b;
}
.home_instagram_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 3.125rem;
  padding: 5.625rem 1.5625rem 0;
}
.home_instagram_title h2 {
  font-family: SchnyderSDemi;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  margin: 0 0 1.25rem;
  font-size: 2.25rem;
  line-height: 2.875rem;
  color: #c2b299;
}
.home_instagram_title a {
  line-height: 2.8125rem;
  overflow: hidden;
}
.home_instagram_title a span {
  font-family: GTAmerica, Helvetica;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  transition: opacity 0.4s;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 0 0.3125rem 0 0;
}
.home_instagram_title a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  margin: 0 0.3125rem 0 0;
  color: #fff;
}
span.home_instagram_title_follow {
}
span.home_instagram_title_underline {
  position: relative;
  margin: 0 !important;
}
span.home_instagram_title_underline:after {
  content: "";
  transition: transform 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  bottom: 0.1875rem;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform-origin: 100% 50%;
}
.home_instagram_title a:hover span.home_instagram_title_follow {
  opacity: 0;
}
.home_instagram_title a:hover span.home_instagram_title_underline:after {
  transform: translateX(100%);
}

.home_instagram_slider {
  height: 9.375rem;
  width: 100%;
}
.home_instagram_slider_wrapper {
  width: 100%;
}
.home_instagram_slide {
}
.home_instagram_slide_image {
  user-select: none;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.home_instagram_slide_image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
}

@media only screen and (min-width: 1024px) {
  .home_instagram {
    padding: 5.625rem 0 0;
  }
  .home_instagram_title {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 4.6875rem;
    padding: 0 5.625rem;
  }
  .home_instagram_title h2 {
    margin: 0;
  }
  .home_instagram_slider {
    height: 20.625rem;
  }
}
