.home_links {
  position: relative;
  background: #1b1b1b;
  padding: 2.5rem 0;
}
.home_links_wrapper {
  position: relative;
  width: 100%;
  padding: 0 1.25rem;
}

.home_link {
  display: block;
  position: relative;
}
.home_link a {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 64.47761%;
  overflow: hidden;
}
.home_link a img {
  position: absolute;
  top: -2.5rem;
  left: 0;
  width: 100%;
  height: calc(100% + 5rem);
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.home_link a:hover img {
  transform: scale(1.05);
}

.home_link .home_link_text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 1.625rem 1.875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.home_link .home_link_text span {
  font-family: GTAmerica, Helvetica;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  letter-spacing: -0.01em;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.75rem;
}
.home_link .home_link_text span:nth-child(2) {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .home_links {
    padding: 4.375rem 0 5.5rem;
  }
  .home_links_wrapper {
    padding: 0 2.8125rem;
    display: flex;
    flex-wrap: wrap;
  }
  .home_link {
    flex: 0 0 50%;
  }
  .home_link a {
    padding: 0 0 58.59155%;
  }
  .home_link .home_link_text {
    padding: 0 3.125rem 3.625rem;
  }
  .home_link .home_link_text span {
    margin-bottom: 0.625rem;
    font-size: 1rem;
    line-height: 1.75rem;
  }
  .home_link .home_link_text span:nth-child(2) {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
}

@media only screen and (min-width: 1440px) {
  .home_links_wrapper {
    max-width: 116.25rem;
    margin: 0 auto;
    padding: 0 5.625rem;
  }
}
