/*------- utility CSS -------*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  min-height: 100% !important;
  height: 100%;
}

body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: unset;
  font-size: unset;
}

ul,
li {
  margin: 0;
  list-style-type: none;
}

a,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  border: none;
  display: block;
  pointer-events: none;
}

input,
textarea,
label {
  display: block;
  outline: none;
  border: none;
  margin: 0;
  background: none;
}

button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

iframe {
  border: none;
}
