.team_detail_wrapper {
  background-color: #1b1b1b;
  padding: 80px 0 0;
}
.team_detail_banner {
  width: 100%;
}
.team_detail_breadcrumbs {
  width: 100%;
  max-width: 720px;
  padding: 0 20px 40px;
  margin: 0 auto 80px;
}
.team_detail_breadcrumbs a {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.15px;
  color: #fff;
}
.team_detail_breadcrumbs a::before {
  content: "";
  width: 13px;
  height: 12px;
  background-image: url(../img/icon/icon-back-white.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  float: left;
  margin-right: 12px;
  margin-top: 0.55em;
}

@media only screen and (min-width: 640px) {
  .team_detail_wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .team_detail_breadcrumbs {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  }
}

@media only screen and (min-width: 992px) {
  .team_detail_breadcrumbs {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .team_detail_breadcrumbs {
    max-width: 1120px;
  }
}

@media only screen and (min-width: 1600px) {
  .team_detail_breadcrumbs {
    max-width: 1600px;
  }
}

.team_detail_id {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 20px 40px;
}
.team_detail_id h1 {
  font-family: GTAmerica;
  font-size: 34px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.45px;
  color: #c2b298;
  margin-bottom: 0.275em;
}
.team_detail_id p {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: -0.43px;
  color: #dbd4c5;
  margin-bottom: 1.25em;
}

@media only screen and (min-width: 992px) {
  .team_detail_id {
    max-width: 1070px;
    padding-bottom: 40px;
  }
  .team_detail_id h1 {
    font-size: 52px;
    line-height: 1.65;
    letter-spacing: -1.87px;
  }
  .team_detail_id p {
    font-size: 22px;
    line-height: 1.64;
    letter-spacing: -0.59px;
  }
}

.team_detail_featured_image {
  margin: 0 auto;
  overflow: hidden;
}
.team_detail_featured_image img {
  width: 100%;
  height: 75vw;
  object-fit: cover;
  object-position: center center;
  max-height: 600px;
}

@media only screen and (min-width: 1600px) {
  .team_detail_featured_image {
    max-width: 1600px;
  }
}

// team detail content
.team_detail_content {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 50px 20px 40px;
}
.team_detail_content h1 {
  font-family: GTAmerica;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  color: #e4dfd4;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.6px;
  margin-bottom: 60px;
}
.team_detail_content p {
  font-family: GTAmerica;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: #e4dfd4;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .team_detail_content {
    padding-top: 150px;
    max-width: 1070px;
    padding-bottom: 40px;
  }
  .team_detail_content h1 {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
  }
}
