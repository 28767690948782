.home_blog_list {
  padding: 5.625rem 1.5625rem 10px;
  width: 100%;
  overflow: hidden;
  background-color: #1b1b1b;
  padding-bottom: 10px;
}
.home_blog_list_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 3.125rem;
  margin-bottom: calc(3.125rem + 24.8967px);
  width: 100%;
}
.home_blog_list_title h2 {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: SchnyderSDemi, Georgia, Times, Times New Roman, serif,
    Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-size: 2.125rem;
  line-height: 2.375rem;
  color: #f2f2f2;
}
.home_blog_list_title a.btn_primary {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  cursor: pointer;
  position: relative;
  padding: 0 2rem;
  display: block;
  min-width: 10rem;
  height: 3.75rem;
  font-size: 1rem;
  line-height: 3.75rem;
  letter-spacing: -0.01em;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
  display: none;
}

.home_blog_list_slider {
  width: 100%;
  display: flex;
  margin: 0 0 3.4375rem;
  flex-wrap: nowrap;
  // custom
  overflow: unset;
  position: relative;
}

.home_blog_list_wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
}

.home_blog_list_slide {
  height: 516.31px;
}

.home_blog_list_slide_wrapper {
  position: relative;
  display: block;
  margin: 0 5.625rem 2.8125rem 0;
  /* width: 17.5rem; */
  flex-shrink: 0;
}

.home_blog_list_image_wrapper {
  position: relative;
  padding: 70.27% 0 0;
  width: 100%;
  overflow: hidden;
  // custom
  width: 110%;
  margin-left: -5%;
}

a.home_blog_list_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;

  clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
  transform: translate3d(0px, 10.3312px, 0px);
  transform: translate3d(0px, 15.6546px, 0px);
  transition: all 0.5s ease-in-out;
}
.home_blog_list_slide:hover a.home_blog_list_image {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}
a.home_blog_list_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home_blog_list_slide h2 {
  font-weight: 400;
  font-weight: 300;
  font-stretch: normal;
  margin: 1.5625rem 0;
  font-size: 1.375rem;
  line-height: 1.8125rem;
  letter-spacing: -0.014em;
  color: #ededed;
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-style: normal;
}
.home_blog_list_slide p {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-stretch: condensed;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: #e4dfd4;
  text-transform: uppercase;
}

.home_blog_list_slide p::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2.8125rem;
  width: 1px;
  height: 100%;
  background: rgba(64, 66, 63, 0.5);
}

.home_blog_list_slider_pagination_wrap {
  // display: none;
  display: flex;
  width: calc(100% - 120px);
  overflow: hidden;
  position: absolute;
  bottom: 0 !important;
  top: auto !important;
  left: 0;
  height: 1px;
  background: hsla(0, 0%, 100%, 0.2);
}
.home_blog_list_slider_pagination nav {
  position: relative;
  width: 100%;
  height: 1px;
}

.home_blog_list_slider_pagination nav span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
}

.home_blog_list_slider_pagination nav span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
  background: #fff;
  transform: translate(0px, 0px);
}

.home_blog_list_slider_pagination span {
  background: #fff !important;
  transition: transform 750ms ease-in-out 250ms !important;
  height: 1px !important;
}

.home_blog_list_slider_btn {
  overflow: visible;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s;
  position: absolute;
  top: auto;
  bottom: -2.5rem;
  bottom: 0;
  left: auto;
  z-index: 1;
  transform: translateY(50%);
}

.home_blog_list_slider_btn.prev {
  right: 60px;
}

.home_blog_list_slider_btn.next {
  right: 0;
}

.home_blog_list_slider_btn svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: #fff;
  transition: all 0.3s;
}

.home_blog_list_slider_btn.prev svg {
  transform: scaleX(-1);
}

@media only screen and (min-width: 1024px) {
  .home_blog_list {
    padding: 5.625rem 5.625rem 3.625rem;
  }

  .home_blog_list_title {
  }
  .home_blog_list_title h2 {
    font-size: 2.875rem;
    line-height: 3.5rem;
    color: #ededed;
  }
  .home_blog_list_title a.btn_primary {
    height: 4.0625rem !important;
    line-height: 4.0625rem;
    -webkit-font-smoothing: antialiased;
    -khtml-font-smoothing: antialiased;
    -apple-font-smoothing: antialiased;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
    font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI,
      Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home_blog_list_slider {
    width: 100%;
  }

  .home_blog_list_wrapper {
  }

  .home_blog_list_slide {
  }

  .home_blog_list_slide_wrapper {
    /* width: 30.625rem; */
  }

  .home_blog_list_image_wrapper {
  }

  a.home_blog_list_image {
    transform: translate3d(0px, 24.8967px, 0px);
    transform: translate3d(0, 0, 0);
  }
  a.home_blog_list_image img {
  }

  .home_blog_list_slide h2 {
  }
  .home_blog_list_slide p {
  }

  .home_blog_list_slider_pagination_wrap {
    bottom: -3.4375rem !important;
  }
  .home_blog_list_slider_btn {
    bottom: -3.4375rem;
  }
}
