.company_hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.company_hero_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.company_hero_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.company_hero_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: SchnyderWideM;
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: -0.43px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
  margin-top: -14vh;
}

@media only screen and (min-width: 768px) {
  .company_hero_text {
    font-size: 44px;
    line-height: 0.73;
    letter-spacing: 2px;
  }
}

// company intro
.company_intro {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c2b299;
  padding: 60px 25px 90px;
}
.company_intro p {
  width: 100%;
  max-width: 1300px;
  font-family: GTAmerica;
  font-size: 32px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.6px;
  color: #1b1b1b;
  margin: 0;
}

@media only screen and (min-width: 640px) {
  .company_intro {
    padding-top: 150px;
    padding-bottom: 200px;
  }
  .company_intro p {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
  }
}

// company factory
.company_factory {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c2b299;
}
.company_factory .company_factory_content {
  padding: 60px 25px 90px;
}
.company_factory .company_factory_content h2 {
  width: 100%;
  max-width: 1300px;
  font-family: GTAmerica;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.6px;
  color: #1b1b1b;
  margin-bottom: 50px;
}
.company_factory .company_factory_content p {
  width: 100%;
  max-width: 1300px;
  margin: 0;
  margin-bottom: 2em;
  font-family: GTAmerica;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.4px;
  color: #1b1b1b;
}
.company_factory .company_factory_content p:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 640px) {
  .company_factory .company_factory_content {
    padding-top: 120px;
    padding-bottom: 150px;
  }
  .company_factory .company_factory_content p {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
    margin-bottom: 1.25em;
  }
  .company_factory .company_factory_content p:last-child {
    font-size: 22px;
    line-height: 1.45;
    letter-spacing: -0.6px;
  }
}

// company module
.company_module {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #c2b299;
}
.company_module_image {
  background-color: #ccc;
  width: 100%;
  max-width: 930px;
  height: 520px;
  position: relative;
}
.company_module_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.company_module_desc {
  width: 100%;
  max-width: 930px;
  background-color: #1b1b1b;
  padding: 60px 25px 90px;
}
.company_module_content {
  padding-top: 40px;
}
.company_module_desc h2 {
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: -0.45px;
  color: #c2b299;
  margin-bottom: 40px;
}
.company_module_desc p {
  font-family: GTAmerica;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.4px;
  color: #e4dfd4;
  margin-bottom: 45px;
}
.company_module_desc a.company_module_btn {
  display: inline-block;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #fff;
  border: 1px solid #fff;
  padding: 20px 50px 22px;
  padding: 20px 45px 21px;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.15, 0.95);
}
.company_module_desc a.company_module_btn:hover {
  color: #1b1b1b;
  background-color: #fff;
}

@media only screen and (min-width: 800px) {
  .company_module {
    flex-direction: row;
  }
  .company_module_image {
    width: 50%;
    height: auto;
    min-height: 100vh;
  }
  .company_module_desc {
    width: 50%;
  }
  .company_module_content {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .company_module {
    padding-right: 5%;
  }
  .company_module_desc {
    padding-top: 100px;
    padding-bottom: 150px;
    margin-bottom: 5%;
  }
  .company_module_content {
    padding-top: 120px;
  }
  .company_module_desc h2 {
    margin-bottom: 50px;
  }
  .company_module_desc p {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1400px) {
  .company_module {
    padding-right: 10%;
  }
  .company_module_desc {
    margin-bottom: 10%;
  }
}
@media only screen and (min-width: 1860px) {
  .company_module {
    justify-content: flex-end;
  }
}
