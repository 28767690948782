@charset "UTF-8";
/*------- custom fonts -------*/
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GTAmerica-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: GTAmerica;
  src: url("../fonts/Gt-America-Font/GT America Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Titillium;
  src: url("../fonts/Titillium-Web/TitilliumWeb-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: SchnyderWideM;
  src: url("../fonts/Schnyder/SchnyderWideM-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: SchnyderSDemi;
  src: url("../fonts/Schnyder/SchnyderS-Demi.woff");
  font-weight: 400;
}
/*------- variables -------*/
:root {
  --main-color: #ed4046;
  --clr11: #ed4046;
  --clr12: #d5383f;
  --clr13: #444444;
  --clr21: #d5383f;
  --clr22: #154054;
  --clr31: #ffd255;
  --clr32: #f5a326;
  --bg11: #ffffff;
  --bg12: #f6f6f6;
  --txt11: #222;
  --bdr11: 1px solid #ddd;
  --bs11: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

:root {
  --container-width-xs: 720px;
  --container-width-sm: 960px;
  --container-width-md: 1120px;
  --container-width-lg: 1420px;
}

/*------- utility CSS -------*/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  min-height: 100% !important;
  height: 100%;
}

body {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  line-height: unset;
  font-size: unset;
}

ul,
li {
  margin: 0;
  list-style-type: none;
}

a,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  border: none;
  display: block;
  pointer-events: none;
}

input,
textarea,
label {
  display: block;
  outline: none;
  border: none;
  margin: 0;
  background: none;
}

button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
button:focus {
  outline: none;
}

iframe {
  border: none;
}

/*------- custom styles -------*/
body {
  font-family: GTAmerica, sans-serif;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: var(--txt11);
  color: #40423f;
  background-color: #555354;
}

.viewport {
  position: relative;
  overflow-x: hidden;
}

.section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.container {
  display: block;
  width: 100%;
  padding: 0 1.5rem;
}

@media only screen and (min-width: 992px) {
  .container {
    max-width: 1440px;
  }
}
.btn_primary {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  padding: 0 1.5625rem;
  height: 3.4375rem !important;
  line-height: 3.4375rem !important;
  min-width: 8.4375rem;
}

.btn_primary .label {
  color: #fff;
  line-height: 1;
  transition: color 0.2s;
}

.btn_primary::before {
  display: none;
}

.btn_primary:hover .label {
  color: #40423f;
}

.btn_primary .canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  z-index: -1;
  will-change: transform;
  background-color: #fff;
}

.btn_2 {
  appearance: none;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
  padding: 1.125rem 2.3125rem 1.25rem;
  border-radius: 2px;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.15, 0.95);
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.btn_2:hover {
  color: #40423f;
  background-color: #ffffff;
}

.btn_hamburger {
  margin-left: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -0.90625rem 0 0;
  width: 2.8125rem;
  height: 2.8125rem;
}

.btn_hamburger_wrap {
  width: 1rem;
  height: 0.5625rem;
  position: relative;
}

.btn_hamburger span {
  transform-origin: 8px 0.5px;
  transform: translate(0px, 0px);
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
}

.btn_hamburger span:nth-child(1) {
  top: 0;
}

.btn_hamburger span:nth-child(2) {
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.btn_hamburger span:nth-child(3) {
  bottom: 0;
}

.link_1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
}

.link_1:hover::after {
  transform: scaleX(1);
}

/*------- header section -------*/
.header {
  width: 100%;
  height: 3.125rem;
  position: fixed;
  top: 0;
  left: 0;
  margin-bottom: -3.125rem;
  padding: 0;
  z-index: 10;
}

.header_bg {
  background-color: rgba(27, 27, 27, 0.85);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.main_nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 99;
  position: relative;
}

ul.main_menu li {
  display: flex;
  position: relative;
  margin-right: 0;
}

ul.main_menu li:last-child {
  margin-right: 0;
}

ul.main_menu li a {
  display: flex;
  height: 5rem;
  line-height: 5rem;
  font-family: GTAmerica, Helvetica;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  letter-spacing: -0.04em;
  text-align: left;
  text-transform: uppercase;
  z-index: 9;
}

ul.main_menu li a span.main_menu_label {
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.025em;
  line-height: 5rem;
  transition: opacity 0.5s;
  font-weight: 400;
}

ul.main_menu li a:hover span.main_menu_label {
  opacity: 0.4;
}

ul.main_menu li a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: opacity 0.4s;
}

ul.main_menu li a:hover:before {
  opacity: 1;
}

ul.primary_menu {
  display: none;
  justify-content: flex-start;
  align-items: center;
}

ul.secondary_menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
  right: auto;
  margin: 0 auto;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}

.header .link_signin {
  display: none;
}

.header .btn_primary {
  padding: 0;
  text-align: center;
  height: 36px !important;
  line-height: 36px !important;
  min-width: 6.75rem;
  margin-right: 0;
}

.header .btn_hamburger {
  margin-right: 0;
}

@media only screen and (min-width: 400px) {
  ul.main_menu li {
    margin-right: 1.875rem;
  }
}
@media only screen and (min-width: 650px) {
  .header .btn_primary {
    padding: 0 0.625rem;
  }

  .logo_wrap {
    left: 0;
    right: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .header {
    height: 5rem;
    padding: 0 5.625rem;
    margin-bottom: -5rem;
  }

  .main_nav {
    justify-content: space-between;
  }

  ul.primary_menu {
    display: flex;
  }

  .header .link_signin {
    display: flex;
  }

  .header .btn_primary {
    padding: 0 1.5625rem;
    height: 3.4375rem !important;
    line-height: 3.4375rem !important;
    min-width: 8.4375rem;
  }

  .header .btn_hamburger {
    margin-right: -0.90625rem;
  }
}
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}

.sidenav {
  padding: 4.0625rem 1.25rem 2.5rem 2.5rem;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #1b1b1b;
  will-change: transform;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(100%);
  overflow: auto;
}

@media only screen and (min-width: 1024px) {
  .sidenav {
    max-width: 23.875rem;
  }
}
.sidenav_list {
  display: flex;
  flex-wrap: wrap;
  padding-right: 2rem;
  margin-bottom: 1.875rem;
}

.sidenav_list li {
  flex-shrink: 0;
  margin-right: 1.875rem;
}

.sidenav_list li a {
  -webkit-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 2.8125rem;
  letter-spacing: 0.015em;
  color: #e5e1cf;
  transition: color 0.5s;
}

.sidenav_list li a:hover {
  color: #797978;
}

.sidenav_menu {
  margin-bottom: 2.1875rem;
}

.sidenav_menu li a,
.sidenav_menu li button {
  -webkit-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  font-family: GTAmerica, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  display: inline-block;
  font-size: 1.25rem;
  line-height: 2.8125rem;
  letter-spacing: 0.01em;
  color: #fff;
  text-decoration: none;
  transition: color 0.5s;
}

.sidenav_menu li button {
  overflow: visible;
  border: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 100%;
  text-align: left;
}

.sidenav_menu li button:hover,
.sidenav_menu li a:hover {
  color: #787878;
}

.btn_expand {
  position: absolute;
  right: 0.3125rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 0.9375rem;
  height: 0.9375rem;
}

.btn_expand span {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 1px;
  width: 100%;
  background: #fff;
}

.btn_expand span:nth-child(2) {
  transform: rotate(90deg);
}

ul.sidenav_expandable_menu {
  padding: 0.625rem 0 0.625rem 1.875rem;
  display: none;
}

ul.sidenav_expandable_menu.expanded {
  display: block;
}

ul.sidenav_expandable_menu li a {
  -webkit-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  font-family: GTAmerica, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 2.75rem;
  letter-spacing: 0.01em;
  color: #e5e1cf;
  transition: color 0.5s;
}

ul.sidenav_expandable_menu li a:hover {
  color: #787878;
}

.btn_close {
  position: absolute;
  top: 0.9375rem;
  right: 0.625rem;
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
}

.btn_close_wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 1.1875rem;
  height: 1.1875rem;
  transform: rotate(-45deg);
}

.btn_close span {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background: #fff;
  will-change: transform;
}

.btn_close span:nth-child(1) {
  width: 100%;
  height: 1px;
}

.btn_close span:nth-child(2) {
  width: 1px;
  height: 100%;
}

ul.sidenav_social {
  margin-bottom: 2.8125rem;
}

ul.sidenav_social li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.625rem;
}

ul.sidenav_social li:first-child {
  margin-left: -0.625rem;
}

ul.sidenav_social li:last-child {
  margin: 0;
}

ul.sidenav_social li a {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: color 0.5s;
  color: #fff;
}

ul.sidenav_social li a:hover {
  color: #8d8d8d;
}

ul.sidenav_social li a i {
  font-size: 1.125rem;
}

.header_2 .header_bg {
  background-color: rgba(198, 199, 200, 0.85);
}

.header_2 ul.main_menu li a span.main_menu_label {
  color: #000;
  font-weight: 700;
}

.header_2 ul.main_menu li a:before {
  background: #000;
}

.header_2 .btn_primary {
  border: 1px solid #000;
}

.header_2 .btn_primary .canvas {
  background-color: #000;
}

.header_2 .btn_primary .label {
  color: #000;
}

.header_2 .btn_primary:hover .label {
  color: #a19fa2;
}

.header_2 .btn_hamburger span {
  background: #000;
}

.footer {
  width: 100%;
}

.footer_subscribe {
  width: 100%;
  padding-top: 3.375rem;
  padding-bottom: 1.75rem;
  background-color: #dbd4c5;
}

.footer_subscribe_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1360px;
  margin: 0 auto;
}

.footer_subscribe_title {
  width: 100%;
  padding-right: 0;
}

.footer_subscribe_title h2 {
  font-family: GTAmerica, Helvetica;
  font-style: normal;
  font-stretch: normal;
  font-size: 1.625rem;
  font-weight: 400;
  letter-spacing: -0.00469rem;
  line-height: 2.625rem;
  color: #000;
}

.footer_subscribe_form {
  width: 100%;
  margin-top: 2.125rem;
}

.subscribe_field_wrap {
  margin-bottom: 1.7rem;
}

.subscribe_field_wrap:nth-child(2) {
  margin-bottom: 0;
}

.subscribe_field {
  margin-bottom: 1.7rem;
  display: flex;
  flex-direction: column;
}

.subscribe_field:last-child {
  margin-bottom: 0;
}

.subscribe_field p {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  font-family: GTAmerica;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: 2.75px;
  color: #787878;
}

.subscribe_field input,
.subscribe_field select {
  font-family: GTAmerica;
  width: 100%;
  height: 64px;
  appearance: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  box-shadow: none;
  border-radius: 0px;
  padding: 22px 25px;
  color: #40423f;
  background-color: white;
  border: none;
}

.subscribe_field select {
  background-image: url(../img/icon/select-bg-black.svg);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
}

.subscribe_field select option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.subscribe_bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.subscribe_check {
  display: flex;
  margin: 2.4rem 0px;
}

.subscribe_check input {
  background-color: transparent;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  appearance: none;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  box-shadow: none;
  border-radius: 0px;
  color: #40423f;
  border: 1px solid #787878;
  margin-right: 1em;
}

.subscribe_check input:checked {
  background-color: white;
  background-image: url(../img/icon/checked.svg);
  background-size: 1.5rem 1.5rem;
  background-position: center center;
}

.subscribe_check span {
  color: #40423f;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 0px;
}

.subscribe_btn {
  appearance: none;
  font-size: 14px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.125px;
  text-align: center;
  cursor: pointer;
  padding: 1rem 4.2rem;
  transition: all 0.5s ease 0s, background-position 0s ease 0s;
  border: 1px solid transparent;
  background-color: #1b1b1b;
  color: #fff;
  margin-bottom: 2.5rem;
  text-transform: none;
  width: auto;
  margin-top: 0;
}

.subscribe_btn:hover {
  background-color: transparent;
  color: #000;
  border-color: #000;
}

p.subscribe_policy {
  color: #40423f;
  font-size: 14px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin: 40px 0px 0px;
}

p.subscribe_policy a {
  color: inherit;
  text-decoration: underline;
}

p.subscribe_policy a:hover {
  text-decoration: none;
}

.footer_bottom {
  width: 100%;
  padding-top: 2.5rem;
  padding-bottom: 1.875rem;
  background-color: #ededed;
}

.footer_bottom_wrapper {
  position: relative;
  width: 100%;
  will-change: transform;
  max-width: 1360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.footer_bottom_col {
  margin-bottom: 2.875rem;
  line-height: 1.4rem;
}

.footer_bottom_col:nth-child(2) {
  margin-bottom: 1.5rem;
}

.footer_social {
  display: flex;
  align-items: center;
}

.footer_social a {
  position: relative;
  margin: 0 1.75rem 0 0;
  display: inline-block;
  color: #1b1b1b;
  user-select: none;
}

.footer_social a::after {
  bottom: -0.9375rem;
}

.footer_social a i {
  width: 21px;
  height: 21px;
  color: #1b1b1b;
}

.footer_bottom_link {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.footer_bottom_link a {
  font-family: GTAmerica, Helvetica;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-size: 0.75rem;
  letter-spacing: -0.01em;
  line-height: normal;
  color: #1b1b1b;
}

.footer_copyright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer_copyright p {
  font-family: GTAmerica, Helvetica;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #40423f;
  font-size: 0.75rem;
  letter-spacing: -0.01em;
  line-height: normal;
}

.footer_copyright p a {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .subscribe_field input,
.subscribe_field select {
    font-size: 17.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer_subscribe {
    padding-top: 6.25rem;
    padding-bottom: 6.5625rem;
  }

  .footer_subscribe_wrap {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer_subscribe_title {
    width: 50%;
    padding-right: 25%;
  }

  .footer_subscribe_title h2 {
    font-size: 2.03125rem;
  }

  .footer_subscribe_form {
    width: 50%;
    margin-top: 0;
  }

  .subscribe_btn {
    font-size: 16px;
    letter-spacing: 0.0125px;
  }

  .footer_bottom {
    padding-top: 6.25rem;
    padding-top: 5rem;
    padding-bottom: 8.125rem;
    padding-bottom: 5rem;
  }

  .footer_bottom_wrapper {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .footer_bottom_col {
    width: 33.33%;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .footer_social a {
    margin: 0 1.875rem 0 0;
  }

  .footer_bottom_link a {
    display: inline-block;
    line-height: 1.75rem;
  }
}
.home_banner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1b1b1b;
}

.home_car_slider_sec {
  padding: 5.625rem 0 3.4375rem;
  background: #1b1b1b;
  text-align: center;
  position: relative;
}

.home_car_slider_bg {
  text-align: center;
  display: none;
  transform: translate3d(0px, 5.7325px, 0px);
}

.home_car_slider_bg_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home_car_slider_bg_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 1024px) {
  .home_car_slider_sec {
    width: 100%;
    padding: 5rem 0;
    height: 100vh;
    overflow: hidden;
  }

  .home_car_slider_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
.home_car_slider {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  overflow: visible;
}

.home_car_slider_btn {
  overflow: visible;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.home_car_slider_btn.prev {
  left: 14.0625rem;
}

.home_car_slider_btn.next {
  right: 14.0625rem;
}

.home_car_slider_btn svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: #fff;
  transition: all 0.3s;
}

.home_car_slider_btn.prev svg {
  transform: scaleX(-1);
}

.home_car_slider_pagination_wrap {
  display: none;
}

.home_car_slider_pagination div {
  position: relative;
  width: 100%;
  height: 1px;
}

.home_car_slider_pagination div span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
}

.home_car_slider_pagination div span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
  background: #fff;
  transform: translate(0px, 0px);
}

.home_car_slider_pagination span {
  background: #fff !important;
  transition: transform 750ms ease-in-out 250ms !important;
  height: 1px !important;
}

@media only screen and (min-width: 1024px) {
  .home_car_slider_pagination_wrap {
    display: flex;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    bottom: -2.5rem !important;
    top: auto !important;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
  }

  .home_car_slider_pagination {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0 !important;
    top: 0 !important;
    padding: 0 5.625rem;
    width: 133.33% !important;
    transform: translateX(-25%);
    z-index: 99999999;
    height: 1px !important;
    background: #333;
  }
}
.home_car_slider_wrapper {
  width: 100%;
  height: 100%;
}

.home_car_slider_slide {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100%;
}

.home_car_slider_slide img {
  transition: transform 250ms ease-in-out;
}

.swiper-slide.home_car_slider_slide.swiper-slide-active img {
  transform: scale(1.14) !important;
}

.swiper-slide.home_car_slider_slide.swiper-slide-prev {
  transform: translateX(40%) scale(1);
}

.swiper-slide.home_car_slider_slide.swiper-slide-next {
  transform: translateX(-40%) scale(1);
}

.home_car_slider_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.home_car_slider_image img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  transform: translate3d(0px, 5.7325px, 0px);
}

.home_vision {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 2.5rem;
  min-height: 100vh;
  overflow: hidden;
}

.home_vision_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  z-index: -3;
}

.home_vision_bg_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.home_vision_bg_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home_vision_text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  will-change: transform;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home_vision_text h3 {
  font-weight: 400;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 300;
  margin: 0 0 1.25rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  color: #fff;
}

.home_vision_text p {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  color: #fff;
  font-family: SchnyderSLight, Georgia, Times, Times New Roman, serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  max-width: 55rem;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.01em;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .home_vision {
    padding: 2.5rem 5.625rem 7.5rem;
    min-height: calc(100vh + 12.5rem);
  }

  .home_vision_text h3 {
    margin: 0 0 2.5rem;
    font-size: 1.125rem;
    line-height: 1.125rem;
    letter-spacing: 0;
  }

  .home_vision_text p {
    font-size: 3.5rem;
    line-height: 4.125rem;
    letter-spacing: 0;
  }
}
.home_blog_list {
  padding: 5.625rem 1.5625rem 10px;
  width: 100%;
  overflow: hidden;
  background-color: #1b1b1b;
  padding-bottom: 10px;
}

.home_blog_list_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 3.125rem;
  margin-bottom: calc(3.125rem + 24.8967px);
  width: 100%;
}

.home_blog_list_title h2 {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: SchnyderSDemi, Georgia, Times, Times New Roman, serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-size: 2.125rem;
  line-height: 2.375rem;
  color: #f2f2f2;
}

.home_blog_list_title a.btn_primary {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
  cursor: pointer;
  position: relative;
  padding: 0 2rem;
  display: block;
  min-width: 10rem;
  height: 3.75rem;
  font-size: 1rem;
  line-height: 3.75rem;
  letter-spacing: -0.01em;
  text-align: center;
  border: 1px solid #fff;
  color: #fff;
  display: none;
}

.home_blog_list_slider {
  width: 100%;
  display: flex;
  margin: 0 0 3.4375rem;
  flex-wrap: nowrap;
  overflow: unset;
  position: relative;
}

.home_blog_list_wrapper {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
}

.home_blog_list_slide {
  height: 516.31px;
}

.home_blog_list_slide_wrapper {
  position: relative;
  display: block;
  margin: 0 5.625rem 2.8125rem 0;
  /* width: 17.5rem; */
  flex-shrink: 0;
}

.home_blog_list_image_wrapper {
  position: relative;
  padding: 70.27% 0 0;
  width: 100%;
  overflow: hidden;
  width: 110%;
  margin-left: -5%;
}

a.home_blog_list_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
  transform: translate3d(0px, 10.3312px, 0px);
  transform: translate3d(0px, 15.6546px, 0px);
  transition: all 0.5s ease-in-out;
}

.home_blog_list_slide:hover a.home_blog_list_image {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

a.home_blog_list_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home_blog_list_slide h2 {
  font-weight: 400;
  font-weight: 300;
  font-stretch: normal;
  margin: 1.5625rem 0;
  font-size: 1.375rem;
  line-height: 1.8125rem;
  letter-spacing: -0.014em;
  color: #ededed;
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-style: normal;
}

.home_blog_list_slide p {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-stretch: condensed;
  font-size: 0.75rem;
  line-height: 1.1875rem;
  color: #e4dfd4;
  text-transform: uppercase;
}

.home_blog_list_slide p::after {
  content: "";
  position: absolute;
  top: 0;
  right: -2.8125rem;
  width: 1px;
  height: 100%;
  background: rgba(64, 66, 63, 0.5);
}

.home_blog_list_slider_pagination_wrap {
  display: flex;
  width: calc(100% - 120px);
  overflow: hidden;
  position: absolute;
  bottom: 0 !important;
  top: auto !important;
  left: 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.home_blog_list_slider_pagination nav {
  position: relative;
  width: 100%;
  height: 1px;
}

.home_blog_list_slider_pagination nav span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
}

.home_blog_list_slider_pagination nav span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
  background: #fff;
  transform: translate(0px, 0px);
}

.home_blog_list_slider_pagination span {
  background: #fff !important;
  transition: transform 750ms ease-in-out 250ms !important;
  height: 1px !important;
}

.home_blog_list_slider_btn {
  overflow: visible;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: 0 0;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s;
  position: absolute;
  top: auto;
  bottom: -2.5rem;
  bottom: 0;
  left: auto;
  z-index: 1;
  transform: translateY(50%);
}

.home_blog_list_slider_btn.prev {
  right: 60px;
}

.home_blog_list_slider_btn.next {
  right: 0;
}

.home_blog_list_slider_btn svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: #fff;
  transition: all 0.3s;
}

.home_blog_list_slider_btn.prev svg {
  transform: scaleX(-1);
}

@media only screen and (min-width: 1024px) {
  .home_blog_list {
    padding: 5.625rem 5.625rem 3.625rem;
  }

  .home_blog_list_title h2 {
    font-size: 2.875rem;
    line-height: 3.5rem;
    color: #ededed;
  }

  .home_blog_list_title a.btn_primary {
    height: 4.0625rem !important;
    line-height: 4.0625rem;
    -webkit-font-smoothing: antialiased;
    -khtml-font-smoothing: antialiased;
    -apple-font-smoothing: antialiased;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
    font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home_blog_list_slider {
    width: 100%;
  }

  .home_blog_list_slide_wrapper {
    /* width: 30.625rem; */
  }

  a.home_blog_list_image {
    transform: translate3d(0px, 24.8967px, 0px);
    transform: translate3d(0, 0, 0);
  }

  .home_blog_list_slider_pagination_wrap {
    bottom: -3.4375rem !important;
  }

  .home_blog_list_slider_btn {
    bottom: -3.4375rem;
  }
}
.home_links {
  position: relative;
  background: #1b1b1b;
  padding: 2.5rem 0;
}

.home_links_wrapper {
  position: relative;
  width: 100%;
  padding: 0 1.25rem;
}

.home_link {
  display: block;
  position: relative;
}

.home_link a {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 64.47761%;
  overflow: hidden;
}

.home_link a img {
  position: absolute;
  top: -2.5rem;
  left: 0;
  width: 100%;
  height: calc(100% + 5rem);
  object-fit: cover;
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.home_link a:hover img {
  transform: scale(1.05);
}

.home_link .home_link_text {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 1.625rem 1.875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home_link .home_link_text span {
  font-family: GTAmerica, Helvetica;
  font-style: normal;
  font-stretch: normal;
  color: #fff;
  letter-spacing: -0.01em;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.home_link .home_link_text span:nth-child(2) {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .home_links {
    padding: 4.375rem 0 5.5rem;
  }

  .home_links_wrapper {
    padding: 0 2.8125rem;
    display: flex;
    flex-wrap: wrap;
  }

  .home_link {
    flex: 0 0 50%;
  }

  .home_link a {
    padding: 0 0 58.59155%;
  }

  .home_link .home_link_text {
    padding: 0 3.125rem 3.625rem;
  }

  .home_link .home_link_text span {
    margin-bottom: 0.625rem;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .home_link .home_link_text span:nth-child(2) {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
}
@media only screen and (min-width: 1440px) {
  .home_links_wrapper {
    max-width: 116.25rem;
    margin: 0 auto;
    padding: 0 5.625rem;
  }
}
.home_instagram {
  width: 100%;
  overflow: hidden;
  background-color: #1b1b1b;
}

.home_instagram_title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 3.125rem;
  padding: 5.625rem 1.5625rem 0;
}

.home_instagram_title h2 {
  font-family: SchnyderSDemi;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  margin: 0 0 1.25rem;
  font-size: 2.25rem;
  line-height: 2.875rem;
  color: #c2b299;
}

.home_instagram_title a {
  line-height: 2.8125rem;
  overflow: hidden;
}

.home_instagram_title a span {
  font-family: GTAmerica, Helvetica;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  transition: opacity 0.4s;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin: 0 0.3125rem 0 0;
}

.home_instagram_title a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
  margin: 0 0.3125rem 0 0;
  color: #fff;
}

span.home_instagram_title_underline {
  position: relative;
  margin: 0 !important;
}

span.home_instagram_title_underline:after {
  content: "";
  transition: transform 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: absolute;
  bottom: 0.1875rem;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transform-origin: 100% 50%;
}

.home_instagram_title a:hover span.home_instagram_title_follow {
  opacity: 0;
}

.home_instagram_title a:hover span.home_instagram_title_underline:after {
  transform: translateX(100%);
}

.home_instagram_slider {
  height: 9.375rem;
  width: 100%;
}

.home_instagram_slider_wrapper {
  width: 100%;
}

.home_instagram_slide_image {
  user-select: none;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.home_instagram_slide_image img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
}

@media only screen and (min-width: 1024px) {
  .home_instagram {
    padding: 5.625rem 0 0;
  }

  .home_instagram_title {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 4.6875rem;
    padding: 0 5.625rem;
  }

  .home_instagram_title h2 {
    margin: 0;
  }

  .home_instagram_slider {
    height: 20.625rem;
  }
}
.blog_list_banner {
  width: 100%;
  background-color: #555354;
}

.blog_list_banner_wrapper {
  width: 100%;
  padding-top: 9.11em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

h2.blog_list_banner_title {
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.35px;
  color: #e1e0dc;
  margin-bottom: 0;
  width: 100%;
  padding: 0;
}

.blog_list_filter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  padding-top: 1.875rem;
}

.blog_list_filter select {
  width: 100%;
  appearance: none;
  border: none;
  border-radius: 0;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 300;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: #fff;
  padding: 1.875em 0 2em;
  margin-bottom: 1.25em;
  background-color: transparent;
  background-image: url(../img/icon/select-bg.svg);
  background-size: 1.5em;
  background-repeat: no-repeat;
  background-position: calc(100% + 6px) 50%;
}

.blog_list_filter select option {
  color: #1b1b1b;
}

@media only screen and (min-width: 768px) {
  .blog_list_banner_wrapper {
    padding-top: 13.28rem;
    padding-bottom: 6.78rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  h2.blog_list_banner_title {
    font-size: 30px;
    line-height: 5.5rem;
    letter-spacing: -0.4px;
    width: 50%;
    max-width: 650px;
  }

  .blog_list_filter {
    width: 50%;
    max-width: 650px;
    justify-content: flex-end;
    padding-left: 1.5rem;
    padding-top: 0;
  }

  .blog_list_filter select {
    max-width: 160px;
    padding: 2em 1.5rem;
    margin-bottom: 0;
    background-position: calc(100% + 6px - 1.5rem) 50%;
    margin-right: -1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .blog_list_banner_wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  h2.blog_list_banner_title {
    padding: 0 1.5rem;
  }

  .blog_list_filter select {
    margin-right: 0;
  }
}
.blog_list_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #787878;
  padding-bottom: 4em;
}

.blog_list {
  width: calc(100% + 1.75rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.875rem;
  padding: 1.5rem 0;
}

.blog_list_item {
  width: 100%;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-right: none;
  max-width: 460px;
  margin: 0 auto;
}

.blog_list_image {
  display: block;
  width: 100%;
  position: relative;
  /* padding-top: 56.26%; */
  margin-bottom: 2rem;
  height: 250px;
}

.blog_list_image a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.blog_list_image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.blog_list_desc {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.blog_list_id {
  display: flex;
  padding: 0;
  margin-bottom: 1rem;
}

.blog_list_id span {
  display: inline-block;
  font-family: GTAmerica;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.23px;
  color: #e4dfd4;
  -webkit-font-smoothing: antialiased;
}

.blog_list_id span::after {
  content: " • ";
}

.blog_list_id span:last-child::after {
  display: none;
}

h2.blog_list_title {
  display: block;
}

h2.blog_list_title a {
  display: flex;
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.76px;
  color: #ededed;
  margin-bottom: 2.25em;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (min-width: 768px) {
  .blog_list {
    padding-top: 2rem;
  }

  .blog_list_item {
    width: 50%;
    margin: 0;
    padding-bottom: 2em;
    max-width: unset;
  }

  .blog_list_item:nth-child(2n-1) {
    border-right: 1px solid #979797;
  }

  .blog_list_id {
    margin-bottom: 1em;
  }
}
@media only screen and (min-width: 992px) {
  .blog_list {
    padding-top: 4rem;
  }

  .blog_list_item {
    width: 33.33%;
    padding-bottom: 3em;
  }

  .blog_list_item:nth-child(3n-1),
.blog_list_item:nth-child(3n-2) {
    border-right: 1px solid #979797;
  }

  .blog_list_item:nth-child(3n) {
    border-right: none;
  }

  .blog_list_image {
    margin-bottom: 2em;
  }

  .blog_list_desc {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media only screen and (min-width: 1024px) {
  .blog_list_content {
    padding-bottom: 5em;
  }

  .blog_list {
    padding-top: 4em;
  }

  .blog_list_item {
    padding-bottom: 6em;
  }
}
.blog_list_more {
  display: flex;
  justify-content: center;
}

.blog_detail_wrapper {
  background-color: #e4dfd4;
}

.blog_detail_header {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

.blog_detail_banner {
  padding: 8rem 0 4.5rem;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
}

.blog_detail_banner a {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.15px;
  color: #1b1b1b;
  display: flex;
  align-items: center;
}

.blog_detail_banner a::before {
  content: "";
  width: 13px;
  height: 12px;
  background-image: url(../img/icon/icon-back.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  float: left;
  margin-right: 12px;
}

.blog_detail_id {
  padding: 0 0 1.5em;
  display: flex;
  justify-content: center;
}

.blog_detail_id span {
  display: inline-block;
  font-family: GTAmerica;
  font-size: 12px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: 2;
  letter-spacing: -0.23px;
  text-align: center;
  color: #1b1b1b;
  -webkit-font-smoothing: antialiased;
}

.blog_detail_id span:nth-child(1)::after {
  content: "  •  ";
}

.blog_detail_id span a {
  text-decoration: underline;
  color: inherit;
}

h2.blog_detail_title {
  font-family: SchnyderWideM;
  font-size: 46px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.84px;
  text-align: center;
  color: #1b1b1b;
  padding-bottom: 0.25em;
  max-width: 1100px;
  margin: 0 auto;
}

p.blog_detail_subtitle {
  font-family: GTAmerica;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.4px;
  text-align: center;
  color: #1b1b1b;
  max-width: 1100px;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  .blog_detail_header {
    padding-bottom: 1.5em;
  }

  .blog_detail_banner {
    padding-top: 7.5em;
    padding-bottom: 2.25em;
    margin-bottom: 3.33em;
    border-bottom: 1px solid #fff;
  }

  .blog_detail_id {
    padding-bottom: 2em;
  }

  .blog_detail_id span {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.3px;
  }

  h2.blog_detail_title {
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: -1.58px;
    padding-bottom: 0.5em;
  }

  p.blog_detail_subtitle {
    font-size: 24px;
    line-height: 1.28;
    letter-spacing: -0.4px;
  }
}
.blog_detail_featured_image {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  position: relative;
}

.blog_detail_image_wrap {
  width: 100%;
  padding-bottom: 56.5%;
  position: relative;
}

.blog_detail_featured_image img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

@media only screen and (min-width: 768px) {
  .blog_detail_featured_image {
    width: 720px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) {
  .blog_detail_featured_image {
    width: 960px;
  }
}
@media only screen and (min-width: 1200px) {
  .blog_detail_featured_image {
    width: 1120px;
  }
}
@media only screen and (min-width: 1861px) {
  .blog_detail_featured_image {
    width: 1420px;
  }
}
.blog_detail_content {
  max-width: 1070px;
  margin: 1em auto;
  margin-top: 4em;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
  padding: 0 1.5rem;
}

.blog_detail_content p {
  font-family: GTAmerica;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: #1b1b1b;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 2em;
}

.blog_detail_content p:last-child {
  margin-bottom: 0;
}

.blog_detail_content p a {
  text-decoration: underline;
  color: #40423f;
  word-wrap: break-word;
}

.blog_detail_content p a:hover {
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .blog_detail_content {
    margin-bottom: 2em;
    margin-top: 5em;
    width: 720px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) {
  .blog_detail_content {
    margin-bottom: 3em;
    margin-top: 6em;
    width: 960px;
  }
}
@media only screen and (min-width: 1200px) {
  .blog_detail_content {
    width: 1120px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.56;
    letter-spacing: normal;
  }
}
@media only screen and (min-width: 1861px) {
  .blog_detail_content {
    width: 1420px;
  }
}
.blog_detail_share {
  padding-top: 0;
  padding-bottom: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1070px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}

.blog_detail_share h2 {
  font-family: GTAmerica;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #40423f;
  margin-bottom: 1em;
}

.blog_detail_share ul {
  list-style-type: none;
  display: flex;
}

.blog_detail_share li {
  display: flex;
  margin-right: 1.75em;
}

.blog_detail_share li a {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.3px;
  color: #40423f;
}

.blog_detail_share li a i {
  font-size: 20px;
}

@media only screen and (min-width: 768px) {
  .blog_detail_share {
    width: 720px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 992px) {
  .blog_detail_share {
    width: 960px;
  }
}
@media only screen and (min-width: 1200px) {
  .blog_detail_share {
    width: 1120px;
  }
}
@media only screen and (min-width: 1861px) {
  .blog_detail_share {
    width: 1420px;
  }
}
.team_list_banner {
  width: 100%;
  background-color: #1b1b1b;
}

.team_list_banner_wrapper {
  width: 100%;
  padding-top: 9.11em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1300px;
  margin: 0 auto;
}

h2.team_list_banner_title {
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.35px;
  color: #c2b298;
  margin-bottom: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 48px;
}

@media only screen and (min-width: 640px) {
  .team_list_banner_wrapper {
    padding-top: 13.28rem;
    padding-bottom: 6.78rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  h2.team_list_banner_title {
    font-size: 30px;
    line-height: 5.5rem;
    letter-spacing: -0.4px;
    padding-bottom: 0;
  }
}
.team_list_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #141414;
}

.team_list_wrapper {
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.team_list {
  width: calc(100% + 2rem);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -1rem;
  padding-top: 1.5rem;
}

.team_list_item {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 444px;
}

.team_list_image {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 100%;
  margin-bottom: 2em;
  margin-bottom: 1.5em;
}

.team_list_image a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.team_list_image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.team_list_desc {
  display: flex;
  flex-direction: column;
}

.team_list_name {
  display: flex;
  padding: 0;
}

.team_list_name a {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  font-family: GTAmerica;
  font-size: 22px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.2px;
  color: #fff;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.15, 0.95);
}

.team_list_name a:hover {
  color: #c2b299;
}

h3.team_list_designation {
  font-family: GTAmerica;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #dbd4c5;
}

@media only screen and (min-width: 768px) {
  .team_list_item {
    width: 50%;
    margin: 0;
    padding-bottom: 3em;
    max-width: unset;
  }
}
@media only screen and (min-width: 992px) {
  .team_list {
    padding-top: 4em;
  }

  .team_list_item {
    width: 33.33%;
    padding-bottom: 3em;
  }

  .team_list_image {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .team_list_item {
    padding-bottom: 120px;
  }
}
.team_detail_wrapper {
  background-color: #1b1b1b;
  padding: 80px 0 0;
}

.team_detail_banner {
  width: 100%;
}

.team_detail_breadcrumbs {
  width: 100%;
  max-width: 720px;
  padding: 0 20px 40px;
  margin: 0 auto 80px;
}

.team_detail_breadcrumbs a {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.15px;
  color: #fff;
}

.team_detail_breadcrumbs a::before {
  content: "";
  width: 13px;
  height: 12px;
  background-image: url(../img/icon/icon-back-white.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  float: left;
  margin-right: 12px;
  margin-top: 0.55em;
}

@media only screen and (min-width: 640px) {
  .team_detail_wrapper {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .team_detail_breadcrumbs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}
@media only screen and (min-width: 992px) {
  .team_detail_breadcrumbs {
    max-width: 960px;
  }
}
@media only screen and (min-width: 1200px) {
  .team_detail_breadcrumbs {
    max-width: 1120px;
  }
}
@media only screen and (min-width: 1600px) {
  .team_detail_breadcrumbs {
    max-width: 1600px;
  }
}
.team_detail_id {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 20px 40px;
}

.team_detail_id h1 {
  font-family: GTAmerica;
  font-size: 34px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.45px;
  color: #c2b298;
  margin-bottom: 0.275em;
}

.team_detail_id p {
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: -0.43px;
  color: #dbd4c5;
  margin-bottom: 1.25em;
}

@media only screen and (min-width: 992px) {
  .team_detail_id {
    max-width: 1070px;
    padding-bottom: 40px;
  }

  .team_detail_id h1 {
    font-size: 52px;
    line-height: 1.65;
    letter-spacing: -1.87px;
  }

  .team_detail_id p {
    font-size: 22px;
    line-height: 1.64;
    letter-spacing: -0.59px;
  }
}
.team_detail_featured_image {
  margin: 0 auto;
  overflow: hidden;
}

.team_detail_featured_image img {
  width: 100%;
  height: 75vw;
  object-fit: cover;
  object-position: center center;
  max-height: 600px;
}

@media only screen and (min-width: 1600px) {
  .team_detail_featured_image {
    max-width: 1600px;
  }
}
.team_detail_content {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 50px 20px 40px;
}

.team_detail_content h1 {
  font-family: GTAmerica;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  color: #e4dfd4;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -0.6px;
  margin-bottom: 60px;
}

.team_detail_content p {
  font-family: GTAmerica;
  font-size: 20px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: #e4dfd4;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) {
  .team_detail_content {
    padding-top: 150px;
    max-width: 1070px;
    padding-bottom: 40px;
  }

  .team_detail_content h1 {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
  }
}
.company_hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.company_hero_image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.company_hero_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.company_hero_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: SchnyderWideM;
  font-size: 34px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: -0.43px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  z-index: 1;
  margin-top: -14vh;
}

@media only screen and (min-width: 768px) {
  .company_hero_text {
    font-size: 44px;
    line-height: 0.73;
    letter-spacing: 2px;
  }
}
.company_intro {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c2b299;
  padding: 60px 25px 90px;
}

.company_intro p {
  width: 100%;
  max-width: 1300px;
  font-family: GTAmerica;
  font-size: 32px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.6px;
  color: #1b1b1b;
  margin: 0;
}

@media only screen and (min-width: 640px) {
  .company_intro {
    padding-top: 150px;
    padding-bottom: 200px;
  }

  .company_intro p {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
  }
}
.company_factory {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c2b299;
}

.company_factory .company_factory_content {
  padding: 60px 25px 90px;
}

.company_factory .company_factory_content h2 {
  width: 100%;
  max-width: 1300px;
  font-family: GTAmerica;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: -0.6px;
  color: #1b1b1b;
  margin-bottom: 50px;
}

.company_factory .company_factory_content p {
  width: 100%;
  max-width: 1300px;
  margin: 0;
  margin-bottom: 2em;
  font-family: GTAmerica;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.4px;
  color: #1b1b1b;
}

.company_factory .company_factory_content p:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 640px) {
  .company_factory .company_factory_content {
    padding-top: 120px;
    padding-bottom: 150px;
  }

  .company_factory .company_factory_content p {
    font-size: 42px;
    line-height: 1.33;
    letter-spacing: -0.79px;
    margin-bottom: 1.25em;
  }

  .company_factory .company_factory_content p:last-child {
    font-size: 22px;
    line-height: 1.45;
    letter-spacing: -0.6px;
  }
}
.company_module {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #c2b299;
}

.company_module_image {
  background-color: #ccc;
  width: 100%;
  max-width: 930px;
  height: 520px;
  position: relative;
}

.company_module_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.company_module_desc {
  width: 100%;
  max-width: 930px;
  background-color: #1b1b1b;
  padding: 60px 25px 90px;
}

.company_module_content {
  padding-top: 40px;
}

.company_module_desc h2 {
  font-family: GTAmerica;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: -0.45px;
  color: #c2b299;
  margin-bottom: 40px;
}

.company_module_desc p {
  font-family: GTAmerica;
  font-size: 18px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: -0.4px;
  color: #e4dfd4;
  margin-bottom: 45px;
}

.company_module_desc a.company_module_btn {
  display: inline-block;
  font-family: GTAmerica;
  font-size: 16px;
  font-weight: 400;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #fff;
  border: 1px solid #fff;
  padding: 20px 50px 22px;
  padding: 20px 45px 21px;
  transition: all 0.5s cubic-bezier(0.6, 0.2, 0.15, 0.95);
}

.company_module_desc a.company_module_btn:hover {
  color: #1b1b1b;
  background-color: #fff;
}

@media only screen and (min-width: 800px) {
  .company_module {
    flex-direction: row;
  }

  .company_module_image {
    width: 50%;
    height: auto;
    min-height: 100vh;
  }

  .company_module_desc {
    width: 50%;
  }

  .company_module_content {
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 1200px) {
  .company_module {
    padding-right: 5%;
  }

  .company_module_desc {
    padding-top: 100px;
    padding-bottom: 150px;
    margin-bottom: 5%;
  }

  .company_module_content {
    padding-top: 120px;
  }

  .company_module_desc h2 {
    margin-bottom: 50px;
  }

  .company_module_desc p {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1400px) {
  .company_module {
    padding-right: 10%;
  }

  .company_module_desc {
    margin-bottom: 10%;
  }
}
@media only screen and (min-width: 1860px) {
  .company_module {
    justify-content: flex-end;
  }
}