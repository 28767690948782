.home_vision {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.25rem 2.5rem;
  min-height: 100vh;
  overflow: hidden;
  // custom code
  // margin-top: -30%;
}
.home_vision_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  z-index: -3;
}

.home_vision_bg_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home_vision_bg_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home_vision_text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  will-change: transform;
  // custom code
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home_vision_text h3 {
  font-weight: 400;
  font-family: GTAmerica, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Roboto, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 300;
  margin: 0 0 1.25rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  color: #fff;
}
.home_vision_text p {
  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-stretch: normal;
  position: relative;
  color: #fff;
  font-family: SchnyderSLight, Georgia, Times, Times New Roman, serif,
    Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
  max-width: 55rem;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.01em;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .home_vision {
    padding: 2.5rem 5.625rem 7.5rem;
    min-height: calc(100vh + 12.5rem);
  }
  .home_vision_text h3 {
    margin: 0 0 2.5rem;
    font-size: 1.125rem;
    line-height: 1.125rem;
    letter-spacing: 0;
  }
  .home_vision_text p {
    font-size: 3.5rem;
    line-height: 4.125rem;
    letter-spacing: 0;
  }
}
