.home_car_slider_sec {
  padding: 5.625rem 0 3.4375rem;
  background: #1b1b1b;
  text-align: center;
  position: relative;
}

.home_car_slider_bg {
  text-align: center;
  display: none;
  transform: translate3d(0px, 5.7325px, 0px);
}

.home_car_slider_bg_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.home_car_slider_bg_image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 1024px) {
  .home_car_slider_sec {
    width: 100%;
    padding: 5rem 0;
    height: 100vh;
    overflow: hidden;
  }
  .home_car_slider_bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.home_car_slider {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: -webkit-grab;
  cursor: grab;
  overflow: visible;
}

.home_car_slider_btn {
  overflow: visible;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  position: relative;
  width: 2.8125rem;
  height: 2.8125rem;
  border-radius: 50%;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  transition: all 0.3s;

  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.home_car_slider_btn.prev {
  left: 14.0625rem;
}

.home_car_slider_btn.next {
  right: 14.0625rem;
}

.home_car_slider_btn svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: #fff;
  transition: all 0.3s;
}

.home_car_slider_btn.prev svg {
  transform: scaleX(-1);
}

.home_car_slider_pagination_wrap {
  display: none;
}

.home_car_slider_pagination div {
  position: relative;
  width: 100%;
  height: 1px;
}

.home_car_slider_pagination div span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
}

.home_car_slider_pagination div span:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  transform-origin: 0 0;
  background: #fff;
  transform: translate(0px, 0px);
}

.home_car_slider_pagination span {
  background: #fff !important;
  transition: transform 750ms ease-in-out 250ms !important;
  height: 1px !important;
}

@media only screen and (min-width: 1024px) {
  .home_car_slider_pagination_wrap {
    display: flex;
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    bottom: -2.5rem !important;
    top: auto !important;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
  }
  .home_car_slider_pagination {
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0 !important;
    top: 0 !important;
    padding: 0 5.625rem;
    width: 133.33% !important;
    transform: translateX(-25%);
    z-index: 99999999;
    height: 1px !important;
    background: #333;
  }
}

.home_car_slider_wrapper {
  //display: flex;
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  height: 100%;
}

.home_car_slider_slide {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  height: 100%;
}

.home_car_slider_slide img {
  transition: transform 250ms ease-in-out;
}

.swiper-slide.home_car_slider_slide.swiper-slide-active img {
  transform: scale(1.14) !important;
}
.swiper-slide.home_car_slider_slide.swiper-slide-prev {
  transform: translateX(40%) scale(1);
}
.swiper-slide.home_car_slider_slide.swiper-slide-next {
  transform: translateX(-40%) scale(1);
}

.home_car_slider_image {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.home_car_slider_image img {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  transform: translate3d(0px, 5.7325px, 0px);
}

// .home_car_slider_item_desc {
//   padding: 2.5rem 0 0;
// }

// .home_car_slider_item_desc h2 {
//   -webkit-user-drag: none;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-font-smoothing: antialiased;
//   -khtml-font-smoothing: antialiased;
//   -apple-font-smoothing: antialiased;
//   font-smooth: always;
//   -moz-osx-font-smoothing: grayscale;
//   font-style: normal;
//   font-family: SchnyderSDemi, Georgia, Times, Times New Roman, serif,
//     Apple Color Emoji, Segoe UI Emoji;
//   font-weight: 400;
//   font-stretch: normal;
//   margin: 0 0 0.875rem;
//   font-size: 2.875rem;
//   line-height: 3.5rem;
//   color: #e4dfd4;

//   opacity: 1;
//   visibility: inherit;
//   transform: translate3d(0px, -19.404px, 0px);
// }
