/*------- variables -------*/
:root {
  --main-color: #ed4046;

  --clr11: #ed4046;
  --clr12: #d5383f;
  --clr13: #444444;

  --clr21: #d5383f;
  --clr22: #154054;

  --clr31: #ffd255;
  --clr32: #f5a326;

  --bg11: #ffffff;
  --bg12: #f6f6f6;

  --txt11: #222;

  --bdr11: 1px solid #ddd;

  --bs11: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

:root {
  --container-width-xs: 720px;
  --container-width-sm: 960px;
  --container-width-md: 1120px;
  --container-width-lg: 1420px;
}
